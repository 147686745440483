export default function ($routeSegmentProvider) {

  "ngInject";

  $routeSegmentProvider
    .when('/bicycle-owner/service-providers/favorite', 'bicycle-owner.favorite-service-providers')
    .when('/bicycle-owner/service-providers/search', 'bicycle-owner.search-service-providers')
    .when('/bicycle-owner/service-providers/favorite/:id', 'bicycle-owner.favorite-service-provider-info')
    .when('/bicycle-owner/service-providers/search/:id', 'bicycle-owner.search-service-provider-info')
    .when('/bicycle-owner/service-providers/:id', 'bicycle-owner.service-provider-info')

    .within('bicycle-owner')

    .segment('favorite-service-providers', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'bicycle_owner.breadcrumb.favorite_mechanics'
      }],
      template: '<bicycle-owner-favorite-service-providers-page></bicycle-owner-favorite-service-providers-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-favorite-service-providers.module" */ "./favorite/bicycle-owner-favorite-service-providers.module");
      }
    })
    .segment('search-service-providers', {
      access: {requireLogin: true},
      breadcrumb: {
        label: 'bicycle_owner.breadcrumb.search_mechanics',
      },
      template: '<bicycle-owner-search-service-providers-page></bicycle-owner-search-service-providers-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-search-service-providers.module" */ "./search/bicycle-owner-search-service-providers.module");
      }
    })
    .segment('service-provider-info', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'bicycle_owner.breadcrumb.mechanic_profile'
      }],
      template: '<bicycle-owner-service-provider-info-page></bicycle-owner-service-provider-info-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-service-provider-info.module" */ "./info/bicycle-owner-service-provider-info.module");
      }
    })
    .segment('favorite-service-provider-info', {
      access: {requireLogin: true},
      breadcrumb: [
        {
          label: 'bicycle_owner.breadcrumb.favorite_mechanics',
          url: '/bicycle-owner/service-providers/favorite'
        },
        {
          label: 'bicycle_owner.breadcrumb.mechanic_profile'
        }
      ],
      template: '<bicycle-owner-service-provider-info-page></bicycle-owner-service-provider-info-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-service-provider-info.module" */ "./info/bicycle-owner-service-provider-info.module");
      }
    })
    .segment('search-service-provider-info', {
      access: {requireLogin: true},
      breadcrumb: [
        {
          label: 'bicycle_owner.breadcrumb.search_mechanics',
          url: '/bicycle-owner/service-providers/search'
        },
        {
          label: 'bicycle_owner.breadcrumb.mechanic_profile'
        }
      ],
      template: '<bicycle-owner-service-provider-info-page></bicycle-owner-service-provider-info-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-service-provider-info.module" */ "./info/bicycle-owner-service-provider-info.module");
      }
    });

}
