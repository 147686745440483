export default function ($routeSegmentProvider) {

  "ngInject";

  $routeSegmentProvider
    .when('/bicycle-owner/book-service', 'bicycle-owner.book-service')
    .when('/bicycle-owner/service-bookings', 'bicycle-owner.service-bookings')
    .when('/bicycle-owner/service-bookings/:id', 'bicycle-owner.service-booking-info')
    .when('/bicycle-owner/service-bookings/:id/reassign', 'bicycle-owner.reassign-service-booking')

    .within('bicycle-owner')

    .segment('book-service', {
      access: {requireLogin: true},
      breadcrumb: [
        {
          label: 'bicycle_owner.breadcrumb.services',
          url: '/bicycle-owner/service-bookings'
        },
        {
          label: 'bicycle_owner.breadcrumb.service_booking'
        }
      ],
      template: '<bicycle-owner-book-service-page></bicycle-owner-book-service-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-book-service.module" */ "./book-service/bicycle-owner-book-service.module");
      }
    })
    .segment('service-bookings', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'bicycle_owner.breadcrumb.services',
        url: '/bicycle-owner/service-bookings'
      }],
      template: '<bicycle-owner-requests-list-page></bicycle-owner-requests-list-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-requests-list.module" */ "./list/bicycle-owner-requests-list.module");
      }
    })
    .segment('service-booking-info', {
      access: {requireLogin: true},
      breadcrumb: [
        {
          label: 'bicycle_owner.breadcrumb.services',
          url: '/bicycle-owner/service-bookings'
        },
        {
          label: 'bicycle_owner.breadcrumb.service_booking'
        }
      ],
      template: '<bicycle-owner-request-info-page></bicycle-owner-request-info-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-request-info.module" */ "./info/bicycle-owner-request-info.module");
      }
    })
    .segment('reassign-service-booking', {
      access: {requireLogin: true},
      breadcrumb: [
        {
          label: 'bicycle_owner.breadcrumb.services',
          url: '/bicycle-owner/service-bookings'
        },
        {
          label: 'bicycle_owner.breadcrumb.service_booking',
          url: '/bicycle-owner/service-bookings/:id',
          params: {
            id: ':id'
          }
        },
        {
          label: 'bicycle_owner.breadcrumb.reassign_service_booking'
        }
      ],
      template: '<bicycle-owner-reassign-request-page></bicycle-owner-reassign-request-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-reassign-request.module" */ "./reassign/bicycle-owner-reassign-request.module");
      }
    })
}
