export default function ModalsServiceProvider() {
  this.$get = (vuiModalService) => {
    'ngInject';

    function Modals() {
      const self = this;

      self.confirmation = (message, callback) => {
        vuiModalService.showModal({
          template: require('./confirmation/confirmation.modal.html'),
          controller: require('./confirmation/confirmation.modal').default,
          inputs: {
            message: message
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.info = (message, callback) => {
        vuiModalService.showModal({
          template: require('./info/info.modal.html'),
          controller: require('./info/info.modal').default,
          inputs: {
            message: message
          }
        }).then(modal => modal.close
          .then(result => callback ? callback(result) : undefined));
      }

      self.contactUs = (callback) => {
        vuiModalService.showModal({
          template: require('./contact-us/contact-us.modal.html'),
          controller: require('./contact-us/contact-us.modal').default,
        }).then(modal => modal.close
          .then(result => callback ? callback(result) : undefined));
      }

      self.cropImage = (source, title, onUpload, callback) => {
        vuiModalService.showModal({
          template: require('./crop-image/crop-image.modal.html'),
          controller: require('./crop-image/crop-image.modal').default,
          inputs: {
            source: source,
            title: title,
            onUpload: onUpload,
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.addPaymentCard = (onSave, callback) => {
        vuiModalService.showModal({
          template: require('./payment/add-payment-card.modal.html'),
          controller: require('./payment/add-payment-card.modal').default,
          inputs: {
            onSave: onSave
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.changePaymentSource = (sources, currentSource, callback) => {
        vuiModalService.showModal({
          template: require('./change-payment-source/change-payment-source.modal.html'),
          controller: require('./change-payment-source/change-payment-source.modal').default,
          inputs: {
            sources: sources,
            currentSource: currentSource
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.editImages = (title, images, onUpload, callback) => {
        vuiModalService.showModal({
          template: require('./upload-images/upload-images.modal.html'),
          controller: require('./upload-images/upload-images.modal').default,
          inputs: {
            title: title,
            images: images,
            onUpload: onUpload
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.transferBicycle = (callback) => {
        vuiModalService.showModal({
          template: require('./transfer-bicycle/transfer-bicycle.modal.html'),
          controller: require('./transfer-bicycle/transfer-bicycle.modal').default
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.bicyclePublicLink = (fakeId) => {
        vuiModalService.showModal({
          template: require('./bicycle-public-link/bicycle-public-link.modal.html'),
          controller: require('./bicycle-public-link/bicycle-public-link.modal').default,
          inputs: {
            fakeId: fakeId
          }
        }).then(modal => modal.close
          .then(result => {
          }));
      }

      self.addBicycle = (types, loadBrands, callback) => {
        vuiModalService.showModal({
          template: require('./add-bicycle/add-bicycle.modal.html'),
          controller: require('./add-bicycle/add-bicycle.modal').default,
          inputs: {
            types: types,
            loadBrands: loadBrands
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.setMechanicRating = (request, callback) => {
        vuiModalService.showModal({
          template: require('./set-mechanic-rating/set-mechanic-rating.modal.html'),
          controller: require('./set-mechanic-rating/set-mechanic-rating.modal').default,
          inputs: {
            request: request
          }
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

      self.unsatisfiedWithService = (callback) => {
        vuiModalService.showModal({
          template: require('./unsatisfied-with-service/unsatisfied-with-service.modal.html'),
          controller: require('./unsatisfied-with-service/unsatisfied-with-service.modal').default
        }).then(modal => modal.close
          .then(result => callback(result)));
      }

    }

    return new Modals();
  };
}